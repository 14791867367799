export default {
  overloadLinksWithTrackingParams: false,
  overloadTrackingParamsList: [
    "gad",
    "gclid",
    "fblid",
    "utm_source",
    "utm_medium",
    "utm_campaign",
  ],
};
