module.exports = {
  name: "AigLP",
  iconPaths: {
    favicon32: "aiglp/images/favicons/favicon-32x32.png",
    favicon16: "aiglp/images/favicons/favicon-32x32.png",
    appleTouchIcon: "aiglp/images/favicons/apple-touch-icon-152x152.png",
    maskIcon: "aiglp/images/favicons/safari-pinned-tab.svg",
    msTileImage: "aiglp/images/favicons/msapplication-icon-144x144.png",
  },
  themeColor: "#001871",
};
