let defaults = {
  globalSpinnerText: "core.loading",
};

export default {
  state: () => ({
    sections: {
      form1: {
        test: "123",
      },
    },
  }),
  mutations: {},
  actions: {},
  getters: {},
};
