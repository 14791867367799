export default {
  props: {
    inputPrefix: {
      type: [String, Boolean],
      default: false,
    },
    input: {
      type: Object,
      default: () => {},
    },
    position: {
      type: String,
      default: "default",
    },
  },
  methods: {
    isNonEmptyString(str) {
      return utilities.isNonEmptyString(str);
    },
    nlToBr(str, options = null) {
      return this.textToSafeHtml(str, options);
    },
    nl2Br(str, options = null) {
      return this.textToSafeHtml(str, options);
    },
  },
};
