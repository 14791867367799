export default {
  currency: {
    useConversions: false,
    default: "ILS",
    availableCurrencies: ["ILS"],
  },
  productTypes: {
    activeProductTypes: [

    ],
    defaultProductTypes: [],
  },
  order: {
    statuses: ["new", "canceled", "failed", "completed", "pending", "payed"],
  },
};
