export default {
  frameworks: ["we", "animate"],
  frameworkComponents: ["we"],
  scss: ["styles"],
  responsive: {
    mainMobileBreakPoint: "l",
    classMap: {
      xs: 400,
      s: 500,
      m: 767,
      l: 1024,
      xl: 1300,
    },
    responsiveClassPrefix: "break-",
    responsiveClassMode: "mobileFirst",
  },
  //defaultIconSet: "justoppIcons",
  dropdown: {
    animationClass: "animate__fadeInX animate--faster",
    gap: 10,
  },
  weUI: {
    notification: {
      defaultCloseAnimation: "fadeOut",
      defaultDuration: 5000,
    },
    button: {
      defaultTheme: "alt",
    },
    buttonThemes: {
      "lead-5": { value: "lead-5", color1: "lead-5", color2: "bg" },
      "alt-lead-inverse": {value: "alt-lead-inverse", color1: "alt", color2: "lead",},
      alt: { value: "alt", color1: "alt", color2: "lead" },
      "lead-inverse": { value: "lead-inverse", color1: "lead", color2: "alt" },
      "alt-inverse": { value: "alt-inverse", color1: "alt", color2: "bg" },
    },
    tooltip: {
      animationClassIn: "animate__fadeIn",
      animationClassOut: "animate__fadeOut",
      extraClass: "animate--faster",
    },
  },
  pageTransitionClassIn: "animate animate__fadeIn animate--fast",
  pageTransitionClassOut: "animate animate__fadeOut  animate--faster",
};
