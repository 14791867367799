import NavFactory from "@/client/extensions/helpers/navFactory.js";
export default async ({ asyncOps, store }) => {
  // older code - we did not have navFactory then
  let adminNavItemFactoryFunction;
  let navItems;
  try {
    adminNavItemFactoryFunction =
      await require("@/client/assets/static/core/nav/adminSideNavFactory.js");
    navItems = await adminNavItemFactoryFunction.default({ asyncOps, store });
  } catch (e) {
    return {};
  }

  let nav = new NavFactory(navItems);

  let lpTitleItem = {
    action: {
      type: "title",
    },
    label: "al.admin.sideNav.lpTitle",
  };
  let lpDemoItem = {
    icon: "info",
    label: "al.admin.sideNav.lpDemo",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-landing-type",
          params: { type: "demo" },
        },
      },
    },
  };
  let lpSideFormItem = {
    icon: "plus",
    label: "al.admin.sideNav.lpSideForm",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-landing-type",
          params: { type: "sideForm" },
        },
      },
    },
  };
  let lpFamilyIndexItem = {
    icon: "users",
    label: "al.admin.sideNav.familyIndex",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-landing-type",
          params: { type: "familyIndex" },
        },
      },
    },
  };
  let lpAppartementItem = {
    icon: "users",
    label: "al.admin.sideNav.lpAppartement",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-landing-type",
          params: { type: "appartement" },
        },
      },
    },
  };
  let lpOptionsPage = {
    icon: "handle-horizontal",
    label: "al.admin.sideNav.lpOptionsPage",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-landing-type",
          params: { type: "optionsPage" },
        },
      },
    },
  };
  let mainCategoryItem = nav.getItemChildCopy("entities", "category");

  nav.removeItems(["ecommerceTitle", "order", "product", "vendor", "brand"]);
  nav.addItemsAfter("dashboard", {
    lpTitleItem,
    lpDemoItem,
    lpSideFormItem,
    lpOptionsPage,
    lpFamilyIndexItem,
    lpAppartementItem,
  });
  nav.addItemAfter("generalTitle", "mainCategoryItem", mainCategoryItem);

  return nav.getItems();
};
