export default (props, store) => {
  let debug = config.tracking.debug;
  let category = "";
  let pageName = "";

  let result = {};
  let setCategory = (name) => {
    category = name;
    return result;
  };

  let setPageName = (name) => {
    pageName = name;
    return result;
  };

  let setPage = (page) => {
    setCategory(page.trackingCategory);
    setPageName(page.trackingName);

    return result;
  };

  let track = async (eventName, options = {}) => {
    try {
      //handle text - expose better API
      let clickText = false;
      if (options?.text) {
        clickText = options?.text;
      }
      if (options?.click_text) {
        clickText = options?.click_text;
      }
      if (options?.clickText) {
        clickText = options?.clickText;
      }

      //handle location - expose better API
      let location = false;
      if (options?.section) {
        location = options.section;
      }
      if (options?.location) {
        location = options.location;
      }

      let rawData = {
        event: eventName,
        page_product: pageName,
        page_type: category,
        click_text: clickText,
        location: location,
        ...options,
      };

      let data = {};
      for (const [key, value] of Object.entries(rawData)) {
        if (typeof value !== "string") {
          data[key] = value;
          continue;
        }
        //safely replace special chars
        data[key] = value
          .substring(0, 3200)
          .replace(/[^\w-_, +\u0590-\u05FF\u0600-\u06FF\u0400-\u04FF]/g, "");
      }

      if (debug) {
        info("pusing into datalayer, after making rawData safe", data);
      }

      if (!window.dataLayer) {
        warn("event tracking - no dataLayer on window!");
        if (window) {
          window.dataLayer = [];
        }
        return result;
      }

      window.dataLayer.push(data);
      return result;
    } catch (e) {
      console.log(e);
    }
  };

  result.setCategory = setCategory;
  result.setPageName = setPageName;
  result.setPage = setPage;
  result.track = track;
  result.trackEvent = track;
  result.setCategory = setCategory;
  return result;
};
